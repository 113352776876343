import { atom, getDefaultStore, useAtomValue } from 'jotai'
import Config from '../config/config'

export const themeAtom = atom(Config.THEME)

export const useTheme = () => useAtomValue(themeAtom)

interface ThemeProps {
  primary: { main: string, contrast: string }
  secondary: { main: string, contrast: string }
}

export const setTheme = (theme: ThemeProps) => {
  if (theme) {
    const defaultStore = getDefaultStore()

    defaultStore.set(themeAtom, theme)
  }
}
