import React from 'react';
import styled from 'styled-components';

interface InputPickerProps {
  value: string | number;
  items: Array<{ value: string | number; label: string | number }>;
  onChangeValue: (e: string) => void;
  disabled?: boolean;
}

export default function InputPicker(props: InputPickerProps) {
  return (
    <Select
      disabled={props.disabled}
      value={props.value}
      onChange={(e) => {
        props.onChangeValue(e.target.value);
      }}>
      {props.items.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Select>
  );
}

const Select = styled.select<{ disabled?: boolean }>`
  border: none;
  padding: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.primary.main};
  color: ${(props) => props.theme.primary.contrast};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;
