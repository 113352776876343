import Api from './Api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export async function getCategories () {
  return (await Api.get('categories')).data
}

export function useCategories () {
  return useQuery({
    queryKey: ['categories'],
    queryFn: getCategories
  })
}
