import React, { useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import moment from 'moment';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  PaginationState
} from '@tanstack/react-table';
import TableList from '../../components/TableList';
import AddButton from '../../components/Buttons/AddButton';
import Search from '../../components/Search';
import Filters from '../../components/Filters';
import { useUsers } from '../../api/users';

type Therapist = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  tel: string;
  status: React.ReactNode;
  createdAt: string;
};

const columnHelper = createColumnHelper<Therapist>();
const columns = [
  columnHelper.accessor((row: any) => `${row.firstName} ${row.lastName}`, {
    id: 'firstName',
    cell: (info: any) => info.getValue(),
    header: () => <span>{i18next.t('name')}</span>
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: () => <span>{i18next.t('email')}</span>
  }),
  columnHelper.accessor('tel', {
    header: () => <span>{i18next.t('tel')}</span>
  }),
  columnHelper.accessor('status', {
    cell: (info) => (
      <Status active={!!info.getValue()}>
        {info.getValue() ? i18next.t('enabled') : i18next.t('disabled')}
      </Status>
    ),
    header: () => <span>{i18next.t('status')}</span>
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) => moment(info.getValue()).format('DD MMM YYYY'),
    header: () => <span>{i18next.t('createdAt')}</span>
  })
];

let timeout: any;
export default function Therapists() {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [search, setSearch] = React.useState('');
  const [searchTimeout, setSearchTimeout] = useState('');
  const [data, setData] = useState<Therapist[]>([]);
  const [total, setTotal] = useState(0);
  const [filterStatus, setFilterStatus] = React.useState('all');
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  const therapists = useUsers({
    search: searchTimeout,
    sortKey: sorting?.[0]?.id,
    sortDesc: sorting?.[0]?.desc,
    page: pageIndex,
    perPage: pageSize,
    status: filterStatus,
    role: 'therapist'
  });

  const searchSessions = (text: string) => {
    setSearch(text);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      setSearchTimeout(text);
    }, 1000);
  };

  React.useEffect(() => {
    setData(therapists.data?.data || []);
    setTotal(therapists.data?.total || 0);
  }, [therapists.data?.data]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    pageCount: Math.ceil(total / pageSize),
    manualSorting: true,
    manualPagination: true
  });
  return (
    <Container>
      <AddButton title={i18next.t('therapists')} onClick={() => {}} />
      <SearchContainer>
        <SearchInput value={search} onChangeText={searchSessions} />
        <Filters
          value={filterStatus}
          filters={['all', 'enabled', 'disabled']}
          setFilter={setFilterStatus}
        />
      </SearchContainer>
      <TableList table={table} loading={therapists.isLoading} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 30px;
`;

const Status = styled.div<{ active: boolean }>`
  background-color: ${(props) => (props.active ? 'rgba(34,170,89,0.12)' : 'rgba(232,63,63,0.12)')};
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  padding: 10px;
`;

const SearchInput = styled(Search)``;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
