import Api from './Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export async function getTotalRequests() {
  return (await Api.get('admin/sessions/overview/requests')).data;
}

export function useTotalRequests() {
  return useQuery({
    queryKey: ['total-requests'],
    queryFn: getTotalRequests
  });
}

export async function getTotalSessions() {
  return (await Api.get('admin/sessions/overview/sessions')).data;
}

export function useTotalSessions() {
  return useQuery({
    queryKey: ['total-sessions'],
    queryFn: getTotalSessions
  });
}

export async function getTotalSessionsByCat() {
  return (await Api.get('admin/sessions/overview/sessions-by-cat')).data;
}

export function useTotalSessionsByCat() {
  return useQuery({
    queryKey: ['total-sessions-by-cat'],
    queryFn: getTotalSessionsByCat
  });
}

export async function getSessions(params: any) {
  return (await Api.get('admin/sessions', { params })).data;
}

export function useSessions(params: any) {
  return useQuery({
    queryKey: ['sessions', params],
    queryFn: () => getSessions(params)
  });
}

export async function getSession(sessionId: number) {
  return (await Api.get(`admin/sessions/${sessionId}`)).data;
}

export function useSession(sessionId: number) {
  return useQuery({
    queryKey: ['sessions', sessionId],
    queryFn: () => getSession(sessionId)
  });
}
