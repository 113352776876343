import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import i18next from 'i18next';
import InputText from './inputs/InputText';

interface SearchProps {
  style?: any;
  value: string;
  onChangeText: (e: string) => void;
}
export default function Search(props: SearchProps) {
  const theme = useTheme();
  return (
    <Container style={props.style}>
      <SearchIcon width={25} height={25} stroke={theme.primary.main} />
      <InputText
        placeholder={i18next.t('search')}
        value={props.value}
        onChangeText={props.onChangeText}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  gap: 20px;
`;
