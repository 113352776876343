import React from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import List from '../../components/List';
import { useTherapists } from '../../api/therapists';
import moment from 'moment';

export default function Analytics() {
  const therapists = useTherapists({});
  return (
    <Container>
      <List
        listForm={{
          fullName: i18next.t('name'),
          status: i18next.t('status'),
          tel: i18next.t('tel'),
          email: i18next.t('email'),
          createdAt: i18next.t('createdAt')
        }}
        listData={therapists.data?.map?.((i: any) => ({
          ...i,
          fullName: `${i.firstName} ${i.lastName}`,
          createdAt: moment(i.createdAt).format('DD MMM YYYY'),
          status: (
            <Status active={i.status}>
              {i.status ? i18next.t('enabled') : i18next.t('disabled')}
            </Status>
          )
        }))}
        keyExtractor={(e) => 't-i-' + e.id}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 30px;
`;

const Status = styled.div<{ active: boolean }>`
  background-color: ${(props) => (props.active ? 'rgba(34,170,89,0.12)' : 'rgba(232,63,63,0.12)')};
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  padding: 10px;
`;
