import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import el from '../translations/el'
import en from '../translations/en'

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: {
    el: {
      translation: el
    },
    en: {
      translation: en
    }
  },
  lng: 'el',
  fallbackLng: 'el'
})

export default i18n
