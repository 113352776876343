export default {
  home: 'Home',
  success: 'Success',
  submit: 'Submit',
  name: 'Name',
  back: 'Back',
  fill_all_fields: 'Please fill in all fields',
  no_internet_msg: 'There is no internet connection',
  network_request_failed: 'Network request failed',
  notifications: 'Notifications',
  news: 'News',
  language: 'Language',
  save: 'Save',
  description: 'Description',
  settings: 'Settings',
  tos: 'Terms of service',
  logout: 'Log Out',
  filters: 'Filters',
  requests: 'Requests',
  calendar: 'Calendar',
  sessions: 'Sessions',
  for_me: 'For me',
  for_all: 'For all',
  accept: 'Accept',
  accept_and_change: 'Accept and change',
  change_session_date: 'Change session date',
  set_new_availability: 'Set your new availability',
  add_availability: 'Add availability',
  send_request: 'Send Request',
  next: 'Next',
  email: 'Email',
  password: 'Password',
  reset_password: 'Reset Password',
  login: 'Login',
  user: 'User',
  date: 'Date',
  status: 'Status',
  subject: 'Subject',
  subjects: 'Subjects',
  join_call: 'Join call',
  session_status: {
    requestedTherapist: 'New',
    requestedNewDate: 'Requested new date',
    accepted: 'Scheduled',
    cancelled: 'Canceled',
    rejected: 'Rejected',
    completed: 'Completed'
  },
  pending: 'Pending',
  scheduled: 'Scheduled',
  completed: 'Completed',
  cancelled: 'Cancelled',
  search: 'Search',
  sessions_total_for_range: 'Total requests for the range you selected',
  sessions_completed_total_for_range: 'Total sessions completed for the range you selected',
  users: 'Users',
  therapists: 'Therapists',
  therapist: 'Therapist',
  createdAt: 'Created Date',
  profile: 'Profile',
  analytics: 'Analytics',
  enabled: 'Enabled',
  disabled: 'Disabled',
  all: 'All',
  tel: 'tel',
  _questionnaire: 'Questionnaire',
  questionnaire: {
    about_you: 'About you',
    about_you_description:
      'Describe the issues you are facing so that your psychotherapist can prepare the best possible approach for your session. ',
    issues: 'Issues you face',
    issues_age: 'How long have you been having problems?',
    age: 'What is your age;',
    affected: 'How much is your daily life affected?',
    seen_therapist: 'Have you seen a therapist before?'
  }
};
