import React, { useEffect } from 'react'
import packageFile from '../../package.json'
import { useQueryClient } from '@tanstack/react-query'
import Config from '../config/config'
import { getAccount } from '../api/account'
import { setTheme } from '../atoms/theme'
import LogoIcon from '../assets/icons/logo.svg'
import styled from 'styled-components'

interface SplashProps {
  onFinish?: () => void
}

export default function Splash (props: SplashProps) {
  const queryClient = useQueryClient()

  const loadData = async () => {
    const token = localStorage.getItem('@api_token')
    if (token) {
      Config.API_TOKEN = token
      const promises = []
      if (Config.API_TOKEN) {
        await queryClient.invalidateQueries()

        promises.push(
          queryClient.prefetchQuery({
            queryKey: ['account'],
            queryFn: getAccount
          })
        )
      }
      await Promise.allSettled(promises)
    }

    setTheme(Config.THEME)

    props?.onFinish?.()
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Container>
     <Logo src={LogoIcon}/>
      <Text>
        Feelya Therapist - Version: {packageFile.version}
        {'\n'}Powered by Distemicha
      </Text>
    </Container>
  )
}

const Container = styled.div`
  padding: 50px;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 100px;
`
const Text = styled.p`
  align-items: center;
  justify-content: flex-end;
  color: rgba(0, 0, 0, 0.56);
  text-align: center;
`
const Logo = styled.img`
width: 400px;
    height: 200px;
`
