import Api from './Api';
import { useQuery } from '@tanstack/react-query';

type Params = {
  sortKey?: string;
  sortDesc?: boolean;
  page?: number;
  perPage?: number;
  search?: string;
  status?: string;
  role?: string;
};

export async function getTherapists(params: Params) {
  return (await Api.get('admin/therapists', { params })).data;
}

export function useTherapists(params: Params) {
  return useQuery({
    queryKey: ['therapists', params],
    queryFn: () => getTherapists(params)
  });
}
