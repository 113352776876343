import React from 'react';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import styled from 'styled-components';
import Button from './Button';

interface AddButtonProps {
  onClick: () => void;
  title?: string;
}

export default function AddButton(props: AddButtonProps) {
  return (
    <Container>
      <Title>{props.title}</Title>
      <Add onClick={props.onClick}>
        <PlusIcon width={25} height={25} stroke={'#FFFFFF'} />
      </Add>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  gap: 20px;
`;
const Add = styled(Button)`
  padding: 8px;
`;

const Title = styled.span`
  font-size: 24px;
`;
