import React from 'react';
import i18next from 'i18next';
import styled, { useTheme } from 'styled-components';
import Color from '../utils/colors';
import { ReactComponent as FiltersIcon } from '../assets/icons/filters.svg';

interface FlatListProps {
  value: string;
  filters: string[];
  setFilter: (e: string) => void;
}
export default function Filters(props: FlatListProps) {
  const theme = useTheme();
  return (
    <FiltersRow>
      <FilterContainer>
        <FiltersIcon width={25} height={25} stroke={theme.primary.main} />
        <FilterText>{i18next.t('filters')}</FilterText>
      </FilterContainer>
      <FilterButtonsContainer>
        {props.filters.map((filter: string) => (
          <FilterButton
            key={'f-i-' + filter}
            active={props.value === filter}
            onClick={() => {
              props.setFilter(filter);
            }}>
            <FilterButtonText>{i18next.t(filter)}</FilterButtonText>
          </FilterButton>
        ))}
      </FilterButtonsContainer>
    </FiltersRow>
  );
}

const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: ${(props) => props.theme.primary.contrast};
  border-radius: 20px;
  padding: 10px;
`;

const FilterText = styled.p`
  font-size: 20px;
  line-height: 0;
`;
const FilterButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  background-color: ${(props) => props.theme.primary.contrast};
  border-radius: 20px;
  padding: 10px;
`;
const FilterButton = styled.a<{ active?: boolean }>`
  display: flex;
  border-radius: 10px;
  padding: 0 10px;
  cursor: pointer;
  line-height: 0;
  background-color: ${(props) =>
    props.active ? new Color(props.theme.primary.main).change(0, 0.2).toString() : null};
`;
const FilterButtonText = styled.p`
  font-size: 20px;
`;
