export default {
  home: 'Αρχική',
  tos: 'Όροι Χρήσης',
  success: 'Επιτυχία',
  submit: 'Υποβολή',
  name: 'Όνομα',
  back: 'Πίσω',
  fill_all_fields: 'Παρακαλώ συμπληρώστε όλα τα πεδία',
  no_internet_msg: 'There is no internet connection',
  network_request_failed: 'Network request failed',
  notifications: 'Ειδοποιήσεις',
  news: 'Νέα',
  language: 'Γλώσσα',
  save: 'Αποθήκευση',
  description: 'Περιγραφή',
  settings: 'Ρυθμίσεις',
  logout: 'Αποσύνδεση',
  filters: 'Φίλτρα',
  requests: 'Αιτήματα',
  calendar: 'Ημερολόγιο',
  sessions: 'Συνεδρίες',
  for_me: 'Προς εμένα',
  for_all: 'Προς όλους',
  accept: 'Αποδοχή',
  accept_and_change: 'Αποδοχή και αλλαγή',
  change_session_date: 'Αλλαγή ημερομηνίας συνεδρίας',
  add_availability: 'Προσθήκη διαθεσιμότητας',
  send_request: 'Αποστολή αιτήματος',
  next: 'Επόμενο',
  email: 'Email',
  password: 'Κωδικός',
  reset_password: 'Επαναφορά κωδικού',
  login: 'Είσοδος',
  set_new_availability: 'Ορίστε τη νεα διαθεσιμότητα',
  user: 'Χρήστης',
  date: 'Ημερομηνία',
  status: 'Κατάσταση',
  subject: 'Θέμα',
  subjects: 'Θέματα',
  join_call: 'Είσοδος στην κλήση',
  session_status: {
    requestedTherapist: 'Νέο αίτημα',
    accepted: 'Προγραμματισμένο',
    requestedNewDate: 'Ζητήθηκε νέα ημ/ώρα',
    cancelled: 'Ακυρώθηκε',
    rejected: 'Απορρίφθηκε',
    completed: 'Ολοκληρώθηκε'
  },
  pending: 'Αναμονή',
  scheduled: 'Προγραμματισμένο',
  completed: 'Ολοκληρωμένο',
  cancelled: 'Ακυρώθηκε',
  search: 'Αναζήτηση',
  sessions_total_for_range: 'Το σύνολο των αιτημάτων που έγιναν για το διάστημα που έχετε επιλέξει',
  sessions_completed_total_for_range:
    'Οι ολοκληρωμένες συνεδρίες που έγιναν για το διάστημα που έχετε επιλέξει',
  users: 'Χρήστες',
  therapists: 'Θεραπευτές',
  therapist: 'Θεραπευτής',
  profile: 'Προφίλ',
  createdAt: 'Δημιουργήθηκε',
  analytics: 'Αναφορές',
  enabled: 'Ενεργός',
  disabled: 'Ανενεργός',
  all: 'Όλα',
  'requests-accepted': 'Αποδεκτά αιτήματα',
  'requests-canceled': 'Ακυρωμένα αιτήματα',
  'requests-completed': 'Επιτυχείς συνεδρίες',
  'requests-canceled-24': 'Ακυρωμένα αιτήματα εντός 24ωρών',
  tel: 'Τηλέφωνο',
  _questionnaire: 'Ερωτηματολόγιο',
  firstName: 'Όνομα',
  lastName: 'Επίθετο',
  questionnaire: {
    about_you: 'Σχετικά με εσάς',
    about_you_description:
      'Περιγράψτε τα θέματα που αντιμετωπίζετε, ώστε ο ψυχοθεραπευτής σας να προετοιμάσει την καλύτερη δυνατή προσέγγιση για τη συνεδρία σας. Όλες οι πληροφορίες είναι αυστηρά εμπιστευτικές και ορατές μόνο στον ψυχοθεραπευτή σας.',
    issues: 'Θέματα που αντιμετωπίζετε',
    issues_age: 'Πόσο καιρό αντιμετωπίζετε προβλήματα;',
    age: 'Ποια είναι η ηλικία σας;',
    affected: 'Πόσο επηρεάζεται η καθημερινή σας ζωή;',
    seen_therapist: 'Έχετε επισκεφθεί θεραπευτή στο παρελθόν;'
  }
};
