import Color from './colors';

const mapStatusColor = {
  requestedTherapist: 'rgb(231,201,40)',
  requestedNewDate: 'rgba(138,82,8,1)',
  accepted: 'rgba(8,100,138,1)',
  cancelled: 'rgba(138,8,8,1)',
  rejected: 'rgba(8,100,138,1)',
  completed: 'rgba(34,108,9,1)'
};

export default function getSessionStatusColor({
  action,
  opacity
}: {
  action:
    | 'requestedTherapist'
    | 'accepted'
    | 'requestedNewDate'
    | 'cancelled'
    | 'rejected'
    | 'completed';
  opacity?: number;
}) {
  return new Color(mapStatusColor[action]).change(0, opacity || 1).toString();
}
