import React from 'react';
import { flexRender, Table } from '@tanstack/react-table';
import styled, { useTheme } from 'styled-components';
import Color from '../utils/colors';
import Button from './Buttons/Button';
import InputText from './inputs/InputText';
import InputPicker from './inputs/InputPicker';
import { ThreeDots } from 'react-loader-spinner';

interface TableListProps {
  table: Table<any>;
  loading?: boolean;
  onClickItem?: (e: any) => void;
}

export default function TableList(props: TableListProps) {
  const theme = useTheme();
  return (
    <Container>
      <TableContainer cellSpacing="0" cellPadding="20">
        {props.loading ? (
          <LoadingContainer>
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color={theme.primary.main}
              radius="9"
              ariaLabel="loading"
            />
          </LoadingContainer>
        ) : null}
        <Thead>
          {props.table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <HeaderItem
                      canShort={header.column.getCanSort()}
                      onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽'
                      }[header.column.getIsSorted() as string] ?? null}
                    </HeaderItem>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {props.table.getRowModel().rows.map((row) => (
            <Tr onClick={() => props.onClickItem?.(row.original)} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </TableContainer>
      <PaginationContainer>
        <ButtonPage
          onClick={() => props.table.setPageIndex(0)}
          disabled={!props.table.getCanPreviousPage()}>
          {'<<'}
        </ButtonPage>
        <ButtonPage
          onClick={() => props.table.previousPage()}
          disabled={!props.table.getCanPreviousPage()}>
          {'<'}
        </ButtonPage>
        <ButtonPage onClick={() => props.table.nextPage()} disabled={!props.table.getCanNextPage()}>
          {'>'}
        </ButtonPage>
        <ButtonPage
          onClick={() => props.table.setPageIndex(props.table.getPageCount() - 1)}
          disabled={!props.table.getCanNextPage()}>
          {'>>'}
        </ButtonPage>
        <PageRow>
          <span>Page</span>
          <strong>
            {props.table.getState().pagination.pageIndex + 1} of {props.table.getPageCount()}
          </strong>
        </PageRow>
        <span>| Go to page:</span>
        <InputText
          value={(props.table.getState().pagination.pageIndex + 1).toString()}
          type="number"
          defaultValue={props.table.getState().pagination.pageIndex + 1}
          onChangeText={(e) => {
            const page = e ? Number(e) - 1 : 0;
            props.table.setPageIndex(page);
          }}
          className="border p-1 rounded w-16"
        />
        <InputPicker
          value={props.table.getState().pagination.pageSize}
          items={[
            { label: 'Show 5', value: 5 },
            { label: 'Show 10', value: 10 },
            { label: 'Show 20', value: 20 },
            { label: 'Show 50', value: 50 },
            { label: 'Show 100', value: 100 }
          ]}
          onChangeValue={(e) => props.table.setPageSize(Number(e))}
        />
      </PaginationContainer>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const TableContainer = styled.table`
  background-color: ${(props) => props.theme.primary.contrast};
  padding: 20px;
  border-radius: 20px;
  position: relative;
`;
const Thead = styled.thead``;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  background-color: ${(props) => new Color(props.theme.primary.main).change(0, 0.1).toString()};

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const Tbody = styled.tbody``;

const Td = styled.td``;
const HeaderItem = styled.div<{ canShort: boolean }>`
  ${(props) => (props.canShort ? 'cursor:pointer;' : '')}
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
  align-self: center;
  margin-top: 20px;
`;
const PageRow = styled.span`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const ButtonPage = styled(Button)`
  padding: 5px 20px;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 150px;
  left: 50%;
  align-self: center;
  background-color: #ffffff;
  border-radius: 100px;
  padding: 20px;
`;
