import React from 'react';
import styled from 'styled-components';
import LogoIcon from '../assets/icons/attica_logo.png';
import { logout, useAccount } from '../api/account';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

export default function Header() {
  const account = useAccount();
  return (
    <Container>
      <LeftContainer>
        <LogoContainer>
          <Logo src={LogoIcon} />
        </LogoContainer>
      </LeftContainer>
      <Menu
        menuButton={
          <RightContainer>
            <Username>{`${account.data?.firstName} ${account.data?.lastName}`}</Username>
          </RightContainer>
        }
        transition>
        <MenuItem
          onClick={() => {
            logout();
          }}>
          Logout
        </MenuItem>
      </Menu>
    </Container>
  );
}
const Container = styled.div`
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 20px 50px;
  justify-content: space-between;
  display: flex;
`;
const LogoContainer = styled.div``;
const Logo = styled.img`
  width: 200px;
  height: 100px;
`;
const LeftContainer = styled.div`
  flex-direction: row;
  gap: 50px;
  align-items: center;
  display: flex;
`;
const RightContainer = styled.div`
  flex-direction: row;
  gap: 50px;
  align-items: center;
  display: flex;
  cursor: pointer;
`;
const Username = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: ${(props) => props.theme.primary.main};
`;
