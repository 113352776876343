import Api, { queryClient } from './Api';
import { useMutation, useQuery } from '@tanstack/react-query';
import Config from '../config/config';
import PushNotifications from '../utils/PushNotifications';

export async function getAccount() {
  return (await Api.get('account')).data;
}

export function useAccount() {
  return useQuery({ queryKey: ['account'], queryFn: getAccount });
}

export const logout = async () => {
  if (Config.API_TOKEN) {
    try {
      await Api.post('account/logout');
    } catch {}
  }
  Config.API_TOKEN = '';
  localStorage.clear();
  try {
    queryClient.clear();
    PushNotifications.logout();
  } catch {}
  window.location.replace('/login');
};

export const useLogoutMutation = () => {
  return useMutation({
    mutationFn: logout
  });
};
