import Api from './Api'
import { useQuery } from '@tanstack/react-query'

export async function getAtticaAnalytics (filters: FiltersType) {
  return (await Api.get('attica/analytics', { params: filters })).data
}

interface FiltersType {
  dateFrom?: Date
  dateTo?: Date
  usersGroup?: string
}

export function useAtticaAnalytics (filters: FiltersType) {
  return useQuery({
    queryKey: ['attica-analytics', filters],
    queryFn: async () => await getAtticaAnalytics(filters)
  })
}
