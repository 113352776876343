import React from 'react';
import ModalComponent from 'react-modal';
import styled from 'styled-components';
import Button from '../Buttons/Button';

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
}

export default function Modal(props: ModalProps) {
  return (
    <ModalComponent style={customStyles} isOpen={props.isVisible} onRequestClose={props.onClose}>
      <Container>
        <HeaderContainer>
          <h2>{props.title}</h2>
          <CloseButton onClick={props.onClose} title={'✕'} />
        </HeaderContainer>
        <Content>{props.children}</Content>
      </Container>
    </ModalComponent>
  );
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%'
  }
};

const Container = styled.div``;
const Content = styled.div``;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;
const CloseButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.67);
  min-width: 50px;
  width: 50px;
  min-height: 50px;
  height: 50px;
  padding: 0;
  font-size: 40px;
`;
