import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import Color from '../../utils/colors';
import EmailIcon from '../../assets/icons/email.png';
import SessionIcon from '../../assets/icons/session.png';
import UsersIcon from '../../assets/icons/users.png';
import { useAtticaAnalytics } from '../../api/attica';
import { useCategories } from '../../api/categories';
import Button from '../../components/Buttons/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import Api from '../../api/Api';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import InputPicker from '../../components/inputs/InputPicker';

export default function Dashboard() {
  const [dateFrom, setDateFrom] = useState(DateTime.now().minus({ days: 7 }).toJSDate());
  const [dateTo, setDateTo] = useState(new Date());
  const [usersGroup, setUsersGroup] = useState('');

  const analytics = useAtticaAnalytics({ dateFrom, dateTo, usersGroup });

  const categories = useCategories();

  const onDateChange = (dates: Date[]) => {
    const [start, end] = dates;
    setDateFrom(start);
    setDateTo(end);
  };

  const downloadReport = async () => {
    const response = await Api.get('attica/analytics/download', {
      params: { dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString(), usersGroup },
      responseType: 'blob'
    });

    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'report.xlsx');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const chartOptions = useMemo(() => {
    // @ts-ignore
    return {
      chart: {
        width: '100%',
        type: 'pie'
      },
      colors: [
        '#11d07a',
        '#4297a3',
        '#7ef729',
        '#e6b415',
        '#13b69d',
        '#3e1e5e',
        '#43e9d1',
        '#4b8e42',
        '#e777de',
        '#333a95',
        '#cfb0da',
        '#44f669',
        '#080d90',
        '#0b48ba',
        '#409c72',
        '#2dc608',
        '#c01992',
        '#8692ef',
        '#2baca7',
        '#2a054e',
        '#b6b7b3',
        '#cdbaac',
        '#24832d',
        '#2824aa',
        '#4e4ea8',
        '#ce3546',
        '#20ac43',
        '#e4d1f5',
        '#10ea60'
      ],
      labels:
        analytics.data?.byCategory?.map?.(
          (i: any) => categories.data?.find((j: any) => j.id === i.categoryId)?.name || ''
        ) || [],
      responsive: [
        {
          breakpoint: 1300,
          // @ts-ignore
          chart: {
            height: 500
          },
          options: {
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    } as ApexOptions;
  }, [analytics.data, categories.data]);

  const chartSeries = useMemo(() => {
    return analytics.data?.byCategory?.map?.((i: any) => i.total) || [];
  }, [analytics.data]);

  return (
    <Container>
      <ControlsContainer>
        <Button title={'Λήψη Αναφοράς'} onClick={downloadReport} />

        <RightControlsContainer>
          <InputPicker
            items={[
              { label: 'Ολο το Προσωπικό', value: '' },
              {
                label: 'Προσωπικό Πλοίων',
                value: 'sea'
              },
              { label: 'Προσωπικό Γραφείων', value: 'land' }
            ]}
            value={usersGroup}
            onChangeValue={(v) => setUsersGroup(v)}
          />
          <DatePicker
            selected={dateFrom}
            // @ts-ignore
            onChange={onDateChange}
            startDate={dateFrom}
            endDate={dateTo}
            selectsRange
            dateFormat="dd/MM/yyyy"
            customInput={<DateInput />}
          />
        </RightControlsContainer>
      </ControlsContainer>

      <Row>
        <Col>
          <Card>
            <Row>
              <Col>
                <CardTitle>{i18next.t('requests')}</CardTitle>
                <BigText>{analytics.data?.requests || 0}</BigText>
              </Col>
              <EmailImage src={EmailIcon} />
            </Row>
            <TotalRequestsContainer>
              <Text>{i18next.t('sessions_total_for_range')}</Text>
            </TotalRequestsContainer>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardTitle>{i18next.t('subjects')}</CardTitle>
            {!!chartSeries?.length && (
              <Chart options={chartOptions} series={chartSeries} type="pie" />
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <SessionsCol>
          <Card>
            <Row>
              <Col>
                <CardTitle>{i18next.t('sessions')}</CardTitle>
                <BigText>{analytics.data?.sessions || 0}</BigText>
                <TotalRequestsContainer>
                  <Text>{i18next.t('sessions_completed_total_for_range')}</Text>
                </TotalRequestsContainer>
              </Col>
              <Col>
                <SessionImg src={SessionIcon} />
              </Col>
            </Row>
          </Card>
        </SessionsCol>
        <SessionSecondCol>
          <Card>
            <UsersImg src={UsersIcon} />
            <CardTitle>{i18next.t('users')}</CardTitle>
            <BigText>{analytics.data?.users || 0}</BigText>
          </Card>
        </SessionSecondCol>
      </Row>
    </Container>
  );
}

function DateInput(props: any) {
  return <Button onClick={props.onClick} title={props.value} />;
}

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
`;

const RightControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    min-width: 100%;
  }
`;

const SessionsCol = styled.div`
  flex: 1;
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const SessionSecondCol = styled.div`
  display: flex;
  flex-direction: column;
`;
const Card = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px 50px;
  border-radius: 20px;
  justify-content: space-between;
`;
const CardTitle = styled.span`
  font-weight: bold;
  font-size: 20px;
  color: ${(props) => new Color(props.theme.primary.main).change(-80).toString()};
`;
const BigText = styled.span`
  font-weight: bold;
  font-size: 60px;
  padding-top: 20px;
  color: ${(props) => new Color(props.theme.primary.main).change(-80).toString()};
`;
const EmailImage = styled.img`
  height: 100px;
  object-fit: contain;
`;

const Text = styled.span``;
const TotalRequestsContainer = styled.div`
  margin-top: 70px;
`;

const SessionImg = styled.img`
  height: 300px;
  width: 100%;
  right: 20px;
  top: 0;
  bottom: 0;
  object-fit: contain;
`;

const UsersImg = styled.img`
  width: 100%;
  object-fit: cover;
`;
