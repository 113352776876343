import axios from 'axios'
import Config from '../config/config'
import { QueryClient } from '@tanstack/react-query'
import { logout } from './account'

const headers: Record<string, string> = {}

const Api = axios.create({
  baseURL: Config.API_URL,
  timeout: 60000,
  headers
})

export const ApiAnon = axios.create({
  baseURL: Config.API_URL,
  timeout: 60000,
  headers
})

Api.interceptors.response.use(
  response => {
    if (
      response.data &&
      response.data.status === 'error' &&
      response.data.error === 'login_required'
    ) {
      logout()
    }
    return response
  },
  async error => {
    if (error.response && error.response.status === 401) {
      logout()
    }
    return await Promise.reject(error)
  }
)

Api.interceptors.request.use(
  data => {
    data.headers.Authorization = `Bearer ${Config.API_TOKEN}`
    return data
  },

  async error => await Promise.reject(error)
)

export default Api

type ImageFileProps = Array<{
  fileName: string
  type: string
  uri: string
}>
export const uploadImages = async (images: ImageFileProps = []) => {
  const data = new FormData()
  for (let x = 0; x < images.length; x++) {
    data.append('images', JSON.stringify({
      name: images[x].fileName,
      type: images[x].type,
      uri: images[x].uri.replace('file://', '')
    }))
  }

  return await fetch(Config.API_URL + 'files/upload', {
    method: 'post',
    body: data,
    headers: {
      Token: Config.API_TOKEN
    }
  }).then(async res => await res.json())
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => {
        if (typeof queryKey[0] === 'string') {
          const reqConfig =
            typeof queryKey[1] === 'object' ? { params: queryKey[1] } : undefined
          return (await Api.get(queryKey[0], reqConfig)).data?.data
        }
        return null
      },
      gcTime: 1000 * 60 * 60 * 24 * 7
    }
  }
})
