import React, { Component } from 'react';
import CheckBox from './CheckBox';
import styled from 'styled-components';
import Color from '../utils/colors';

interface ListProps {
  style?: any;
  listForm: object;
  listData: any[];
  viewOnly?: boolean;
  loading?: boolean;
  disableSort?: boolean;
  columnsStyle?: any;
  sortBy?: SortBy;
  changeSortBy?: (index: number) => SortBy;
  onPressItem?: () => void;
  onLongPressItem?: () => void;
  onRefresh?: () => void;
  onEndReached?: () => void;
  keyExtractor: (item: any, index: number) => string;
  getCheckedValues?: (e: Array<number | string>) => void;
  onPressMenuItem?: (e: Array<number | string>) => void;
  add?: () => void;
}
interface ListStates {
  checkedIds: number[] | string[];
}

type SortBy = { key: string; sortOrder: number };

export default class List extends Component<ListProps, ListStates> {
  state = {
    checkedIds: []
  };

  checkItem = (itemId: number | string) => {
    const items = JSON.parse(JSON.stringify(this.state.checkedIds));
    if (items.includes(itemId)) {
      items.splice(items.indexOf(itemId), 1);
    } else {
      items.push(itemId);
    }
    this.setState({ checkedIds: items });
    this.props?.getCheckedValues?.(items);
  };

  renderList = (item: any) => {
    const itemArray = Object.keys(this.props.listForm);
    return (
      <Item onClick={this.props?.onPressItem}>
        {typeof this.props.getCheckedValues === 'function' && typeof item.id === 'number' ? (
          <CheckBox
            onPress={() => this.checkItem(item.id)}
            checked={
              // @ts-ignore
              this.state.checkedIds.includes(item.id)
            }
          />
        ) : null}
        <ItemCols>
          {itemArray.map((e, indexContent) => (
            <ItemValue
              key={e + '_' + '_CardBlockListItemTitle'}
              style={this.props.columnsStyle?.[Object.keys(this.props.listForm)[indexContent]]}>
              {item[e] || ''}
            </ItemValue>
          ))}
        </ItemCols>
      </Item>
    );
  };

  add = () => {
    this.props.add?.();
  };

  sortBy = (index: number) => {
    this.props.changeSortBy?.(index);
  };

  render() {
    const titleArray = [];
    let i = 0;
    if (this.props.listData) {
      for (i; i < Object.values(this.props.listForm).length; i++) {
        titleArray.push(Object.values(this.props.listForm)[i]);
      }
    }
    return (
      <Container>
        <HeaderContainer>
          {titleArray.map((item, index) => (
            <HeaderTitle
              onClick={() => this.sortBy(index)}
              key={item?.id + item + '_CardBlockListTitle'}
              style={this.props.columnsStyle?.[Object.keys(this.props.listForm)[index]]}>
              {item}
              {this.props.sortBy
                ? this.props.sortBy.key === Object.keys(this.props.listForm)[index] &&
                  this.props.sortBy.sortOrder === 1
                  ? ' ▼'
                  : this.props.sortBy.key === Object.keys(this.props.listForm)[index] &&
                      this.props.sortBy.sortOrder === 0
                    ? ' ▲'
                    : ' ⯈'
                : ''}
            </HeaderTitle>
          ))}
        </HeaderContainer>

        <ListItems
        // ListFooterComponent={this.props.loading ? <Spinner size={'large'} /> : null}
        // ItemSeparatorComponent={() => <ItemsSeparator />}
        // ListEmptyComponent={
        //   <EmptyContainer>
        //     <EmptyText>Nothing Found</EmptyText>
        //   </EmptyContainer>
        // }
        >
          {this.props.listData?.map?.(this.renderList)}
        </ListItems>
      </Container>
    );
  }
}

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
`;
const EmptyContainer = styled.div``;
const EmptyText = styled.p``;
const Item = styled.a`
  padding: 15px 20px;
`;
const ItemCols = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
`;
const ItemText = styled.p`
  width: 200px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.75);
`;
const ItemValue = styled.div`
  width: 200px;
`;
const ListItems = styled.div``;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => new Color(props.theme.primary.main).change(0, 0.1).toString()};
  padding: 0 20px;
  border-radius: 10px;
  gap: 20px;
`;
const HeaderTitle = styled.p`
  width: 200px;
  font-size: 18px;
`;
const ItemsSeparator = styled.div`
  background-color: rgba(0, 0, 0, 0.11);
  width: 100%;
  height: 1px;
`;
