import React, { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import i18next from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import InputText from '../../components/inputs/InputText';
import Button from '../../components/Buttons/Button';
import { useUser, useUserMutation } from '../../api/users';
import { useNavigate, useParams } from 'react-router-dom';
import CheckBox from '../../components/CheckBox';

export default function User() {
  const userMutation = useUserMutation();

  const { id } = useParams();
  const user = useUser(id);
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      id: 'new',
      firstName: '',
      lastName: '',
      email: '',
      tel: '',
      status: false
    }
  });

  useEffect(() => {
    console.log(user.data);
    if (user.data?.id) {
      setValue('id', user.data.id);
      setValue('firstName', user.data.firstName);
      setValue('lastName', user.data.lastName);
      setValue('email', user.data.email);
      setValue('tel', user.data.tel);
      setValue('status', user.data.status);
    }
  }, [user.data]);
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    userMutation.mutate({ data }, { onSuccess: () => navigate('/users') });
  };
  return (
    <Container>
      <Content>
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputTextStyled label={i18next.t('firstName')} onChangeText={onChange} value={value} />
          )}
          name="firstName"
        />
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputTextStyled label={i18next.t('lastName')} onChangeText={onChange} value={value} />
          )}
          name="lastName"
        />
        <Controller
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputTextStyled label={i18next.t('email')} onChangeText={onChange} value={value} />
          )}
          name="email"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputTextStyled label={i18next.t('tel')} onChangeText={onChange} value={value} />
          )}
          name="tel"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <CheckBoxStyled onPress={onChange} checked={value}>
              {i18next.t('status')}
            </CheckBoxStyled>
          )}
          name="status"
        />
      </Content>
      <Button
        loading={userMutation.isPending || user.isLoading}
        onClick={handleSubmit(onSubmit)}
        title={i18next.t('save')}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 30px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
`;

const InputTextStyled = styled(InputText)`
  width: 200px;
`;

const CheckBoxStyled = styled(CheckBox)`
  width: 200px;
`;
