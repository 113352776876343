import Api from './Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
type Params = {
  sortKey?: string;
  sortDesc?: boolean;
  page?: number;
  perPage?: number;
  search?: string;
  status?: string;
  role?: string;
};

export async function getTotalUsers() {
  return (await Api.get('admin/users/overview')).data;
}

export function useTotalUsers() {
  return useQuery({
    queryKey: ['total-users'],
    queryFn: getTotalUsers
  });
}

export async function getUsers(params: Params) {
  return (await Api.get('admin/users', { params })).data;
}

export function useUsers(params: Params) {
  return useQuery({
    queryKey: ['users', params],
    queryFn: () => getUsers(params)
  });
}

export async function getUser(id?: number | string) {
  if (id !== 'new') {
    return (await Api.get(`admin/users/${id}`)).data;
  }
}

export function useUser(id?: number | string) {
  return useQuery({
    queryKey: ['users', id],
    queryFn: () => getUser(id)
  });
}

export const useUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }: any) => {
      if (typeof data.id === 'number') {
        return Api.put(`admin/users/${data.id}`, data);
      } else {
        return Api.post(`admin/users`, data);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users']
      });
    }
  });
};
