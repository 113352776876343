export default class Config {
  static API_URL = 'https://attica-api.feelya.gr/';
  // static API_URL = 'http://localhost:3444/';

  static API_TOKEN = '';

  static ONESIGNAL_APP_ID = '';

  static SENTRY_DSN = '';

  static THEME = {
    primary: { main: '#004282', contrast: '#FFFFFF' },
    secondary: { main: '#e39c40', contrast: '#FFFFFF' }
  };
}
