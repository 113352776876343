import React, { useState } from 'react';
import { queryClient } from '../api/Api';
import SplashScreen from '../screens/Splash';
// import PushNotifications from '../utils/PushNotifications';
// import Sentry from '../utils/Sentry';
import styled, { ThemeProvider } from 'styled-components';
import { useTheme } from '../atoms/theme';
import { QueryClientProvider } from '@tanstack/react-query';
import DashboardAttica from '../screens/attica/Dashboard';
import DashboardFeelya from '../screens/feelya/Dashboard';
import { createBrowserRouter, Navigate, Router, RouterProvider } from 'react-router-dom';
import Config from '../config/config';
import Header from '../components/Header';
import Login from '../screens/Login';
import { useAccount } from '../api/account';
import Drawer from '../components/Drawer';
import TherapistsFeelya from '../screens/feelya/Therapists';
import SessionsFeelya from '../screens/feelya/Sessions';
import UsersFeelya from '../screens/feelya/Users';
import AnalyticsFeelya from '../screens/feelya/Analytics';
import UserFeelya from '../screens/feelya/User';

// Sentry.init();
//
// PushNotifications.init();

export default function Routes() {
  const theme = useTheme();
  const [splashLoading, setSplashLoading] = useState(true);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {splashLoading ? (
          <SplashScreen
            onFinish={() => {
              setSplashLoading(false);
            }}
          />
        ) : (
          <Navigation />
        )}
      </ThemeProvider>
    </QueryClientProvider>
  );
}

const LayoutAttica = (props: any) => {
  return (
    <Container>
      <ContentContainer>
        <Header />
        {props.children}
      </ContentContainer>
    </Container>
  );
};

const LayoutFeelya = (props: any) => {
  return (
    <Container>
      <Drawer />
      <ContentContainer>{props.children}</ContentContainer>
    </Container>
  );
};

const feelyaRouter = createBrowserRouter([
  {
    path: '/login',
    element: <Login />
  },
  // {
  //   path: '/',
  //   element: (
  //     <LayoutFeelya>
  //       <DashboardFeelya />
  //     </LayoutFeelya>
  //   )
  // },
  {
    path: '/*',
    element: (
      <LayoutFeelya>
        <TherapistsFeelya />
      </LayoutFeelya>
    )
  },
  {
    path: '/therapists',
    element: (
      <LayoutFeelya>
        <TherapistsFeelya />
      </LayoutFeelya>
    )
  },
  {
    path: '/sessions',
    element: (
      <LayoutFeelya>
        <SessionsFeelya />
      </LayoutFeelya>
    )
  },
  {
    path: '/users',
    element: (
      <LayoutFeelya>
        <UsersFeelya />
      </LayoutFeelya>
    )
  },
  {
    path: '/users/:id',
    element: (
      <LayoutFeelya>
        <UserFeelya />
      </LayoutFeelya>
    )
  },
  {
    path: '/analytics',
    element: (
      <LayoutFeelya>
        <AnalyticsFeelya />
      </LayoutFeelya>
    )
  }
]);

const atticaRouter = createBrowserRouter([
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/',
    element: (
      <LayoutAttica>
        <DashboardAttica />
      </LayoutAttica>
    )
  }
]);
const globalRouter = createBrowserRouter([
  {
    path: '/*',
    element: <Login />
  }
]);
function Navigation() {
  if (!Config.API_TOKEN) {
    return <RouterProvider router={globalRouter} />;
  }
  return <AppNavigation />;
}


function AppNavigation() {
  const account = useAccount();

  if (account.data?.role === 'admin') {
    return <RouterProvider router={feelyaRouter} />;
  }
  if (account.data?.role === 'adminAnalytics') {
    return <RouterProvider router={atticaRouter} />;
  }

  return null;
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f5f5f5;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  flex: 1;
`;
