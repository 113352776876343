import React from 'react';
import styled from 'styled-components';

interface CheckBoxProps {
  onPress?: (e: boolean) => void;
  checked: boolean;
  styleCheckBox?: any;
  styleChecked?: any;
  styleContainer?: any;
  style?: any;
  children?: React.ReactNode | React.ReactNode[];
}
export default class CheckBox extends React.Component<CheckBoxProps> {
  static defaultProps = {
    onPress: () => {},
    checked: false
  };

  render() {
    return (
      <CheckBoxPressable {...this.props}>
        <Value
          type={'checkbox'}
          onClick={() => this.props.onPress?.(!this.props.checked)}
          checked={this.props.checked}
        />
        {this.props.children}
      </CheckBoxPressable>
    );
  }
}

const CheckBoxPressable = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
const Container = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  border: 1px ${(props) => props.theme.primary.main} solid;
`;
const Value = styled.input<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: ${(props) => (props.checked ? props.theme.primary.main : 'transparent')};
`;
