import React from 'react';
import Color from '../../utils/colors';
import styled from 'styled-components';

export interface Props {
  loading?: boolean;
  disabled?: boolean;
  title?: string;
  href?: string;
  children?: React.ReactNode;
  style?: any;
  styleText?: any;
  type?: 'primary' | 'secondary';
  outlined?: boolean;
  onClick?: (e: any) => void;
}

export default function Button(props: Props) {
  return (
    <Container {...props} onClick={(e: any) => !props.disabled && props.onClick?.(e)}>
      {props?.title || props.children}
    </Container>
  );
}

const Container = styled.a<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme[props.type || 'primary'].main};
  border-radius: 10px;
  padding: 20px;
  ${(props) => {
    if (props.outlined) {
      return `
  background-color: unset;
  border: 1px solid ${props.theme[props.type || 'primary'].main};
      `;
    }
  }}
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  text-align: center;
  font-weight: 500;
  color: ${(props) => (props.outlined ? props.theme[props.type || 'primary'].main : '#fff')};
  cursor: ${(props) => !props.disabled && 'pointer'};
  user-select: none;
  justify-content: center;

  &:hover {
    background-color: ${(props) =>
      !props.disabled &&
      new Color(props.theme[props.type || 'primary'].main).change(-20).toString()};
  }
`;
