import OneSignal from 'react-onesignal';
import Config from '../config/config';

export default class PushNotifications {
  static logout = OneSignal.logout;
  static addEventListener = OneSignal.Notifications.addEventListener;
  static removeEventListener = OneSignal.Notifications.removeEventListener;

  static async init() {
    await OneSignal.init({
      appId: Config.ONESIGNAL_APP_ID
    });
  }

  static async prompt() {
    await OneSignal.Slidedown.promptPush();
  }

  static async login(userId: number | string) {
    if (userId) {
      await OneSignal.login(userId.toString());
    }
  }

  static async permissions() {
    await OneSignal.Slidedown.promptPush();
  }
}
