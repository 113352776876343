import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/icons/logo.svg';
import Button from './Buttons/Button';
import i18next from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../api/account';

const screens = [
  // { name: i18next.t('home'), path: '/' },
  { name: i18next.t('sessions'), path: '/sessions' },
  { name: i18next.t('therapists'), path: '/therapists' },
  { name: i18next.t('users'), path: '/users' }
  // { name: i18next.t('analytics'), path: '/analytics' }
];

export default function Drawer() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Container>
      <LogoContainer>
        <LogoIcon src={Logo} />
      </LogoContainer>
      <MenuItems>
        {screens.map((s) => (
          <ButtonMenu
            active={s.path === location.pathname}
            key={'menu-i-s-' + s.path}
            title={s.name}
            // href={s.path}
            onClick={() => navigate(s.path)}
          />
        ))}
      </MenuItems>
      <BottomButtons>
        <ProfileButton disabled title={i18next.t('profile')} />
        <LogoutButton onClick={() => logout()} title={i18next.t('logout')} />
      </BottomButtons>
    </Container>
  );
}

const Container = styled.div`
  width: 250px;
  height: 100vh;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: ${(props) => props.theme.primary.main};
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  margin: 50px;
`;
const LogoIcon = styled.img``;
const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 10px;
  gap: 5px;
  flex: 1;
`;

const ButtonMenu = styled(Button)<{ active?: boolean }>`
  ${(props) => (props.active ? 'background-color: #0FAAD3;' : '')}
  color:#FFFFFF;
  padding: 12px;
`;

const ProfileButton = styled(Button)<{ active?: boolean }>`
  font-weight: bold;
  background-color: unset;
  padding: 12px;
`;
const LogoutButton = styled(Button)<{ active?: boolean }>`
  padding: 12px;
  background-color: #7c2121;
`;
const BottomButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  gap: 5px;
`;
