import React, { useState } from 'react';
import { ApiAnon } from '../api/Api';
import Button from '../components/Buttons/Button';
import TextInput from '../components/inputs/InputText';
import { useMutation } from '@tanstack/react-query';
import styled from 'styled-components';
import i18next from 'i18next';
import Config from '../config/config';
import PushNotifications from '../utils/PushNotifications';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const loginMutation = useMutation({
    mutationFn: async () =>
      await ApiAnon.post('admin/account/login', { email, password, role: 'adminAnalytics' }),
    onSuccess: (resp) => {
      const data = resp.data;
      if (data.token) {
        Config.API_TOKEN = data.token;
        localStorage.setItem('@api_token', data.token);
        if (data.id) {
          PushNotifications.login(data.id);
        }
        window.location.replace('/');
      } else if (data.error === 'invalid_credentials') {
        setError(data.message);
      }
    },
    onError: () => {
      setError('Error');
    }
  });

  const login = () => {
    if (email && password) {
      loginMutation.mutate();
    }
  };

  return (
    <Container>
      <Content>
        <TextInput
          type={'email'}
          label={i18next.t('email')}
          placeholder={i18next.t('email')}
          onSubmit={login}
          onChangeText={(text) => {
            setEmail(text);
          }}
          value={email}
        />

        <TextInput
          type={'password'}
          secureTextEntry={true}
          label={i18next.t('password')}
          placeholder={i18next.t('password')}
          onSubmit={login}
          onChangeText={(text) => {
            setPassword(text);
          }}
          value={password}
        />

        <ResetPasswordButton onClick={() => {}}>
          <ResetPasswordButtonText>{i18next.t('reset_password')}</ResetPasswordButtonText>
        </ResetPasswordButton>

        <Error>{error}</Error>
        <Button
          disabled={loginMutation.isPending}
          loading={loginMutation.isPending}
          onClick={login}
          title={i18next.t('login')}
        />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  background-color: ${(props) => props.theme.primary.contrast};
  padding: 50px;
  gap: 10px;
  border-radius: 20px;
  background-color: #f3f3f3;
`;

const ResetPasswordButton = styled.a`
  align-self: flex-end;
  margin-bottom: 10px;
`;

const ResetPasswordButtonText = styled.p``;
const Error = styled.p`
  color: #651515;
`;
