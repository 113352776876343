import React, { useState } from 'react';
import styled from 'styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  style?: React.CSSProperties;
  label?: string;
  value: string;
  inputRef?: React.RefObject<any>;
  secureTextEntry?: boolean;
  onChangeText?: (e: string) => void;
}

export default function InputText(props: Props) {
  return (
    <Container style={props.style}>
      {props.label ? <Label>{props.label}</Label> : null}
      <Input
        {...props}
        onChange={(e) => props.onChangeText?.(e.target.value)}
        ref={props.inputRef}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  border: unset;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
`;
const Label = styled.label`
  padding-left: 20px;
  padding-bottom: 1px;
  color: rgba(0, 0, 0, 0.56);
`;
