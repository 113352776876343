import React from 'react';
import i18next from 'i18next';
import Modal from './Modal';
import styled from 'styled-components';

interface QuestionnareModalProps {
  onClose: () => void;
  user: any;
}

const questionnaireKeys = [
  'about_you',
  'issues',
  'issues_age',
  'age',
  'affected',
  'seen_therapist'
];

export default function QuestionnareModal(props: QuestionnareModalProps) {
  return (
    <Modal isVisible={!!props.user} onClose={props.onClose}>
      <QuestionnaireContainer>
        {questionnaireKeys.filter((i) => !!props.user?.data?.find?.((j: any) => j.key === i))
          ?.length ? (
          questionnaireKeys
            .filter((i) => !!props.user?.data?.find?.((j: any) => j.key === i))
            .map((i) => (
              <QuestionnaireItem key={'q-i-' + i}>
                <QuestionnaireTitle>{i18next.t(`questionnaire.${i}`)}</QuestionnaireTitle>
                <QuestionnaireValue>
                  {props.user?.data?.find?.((j: any) => j.key === i)?.value}
                </QuestionnaireValue>
              </QuestionnaireItem>
            ))
        ) : (
          <EmptyText>Empty</EmptyText>
        )}
      </QuestionnaireContainer>
    </Modal>
  );
}
const QuestionnaireContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
const QuestionnaireItem = styled.div`
  display: flex;
  flex-direction: column;
`;
const QuestionnaireTitle = styled.span`
  color: rgba(42, 42, 42, 0.64);
`;
const QuestionnaireValue = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const EmptyText = styled.span`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.47);
`;
