import React, { useState } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  PaginationState
} from '@tanstack/react-table';
import TableList from '../../components/TableList';
import AddButton from '../../components/Buttons/AddButton';
import Search from '../../components/Search';
import Filters from '../../components/Filters';
import { useNavigate } from 'react-router-dom';
import { useSessions } from '../../api/sessions';
import moment from 'moment';
import getSessionStatusColor from '../../utils/sessions';

type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  tel: string;
  status: React.ReactNode;
  createdAt: string;
};
type Category = {
  id: number;
  name: string;
  type: string;
};
type History = {
  id: number;
  action:
    | 'requestedTherapist'
    | 'accepted'
    | 'requestedNewDate'
    | 'cancelled'
    | 'rejected'
    | 'completed';
  actionBy: string;
};
type Session = {
  id: number;
  user: User;
  therapist: User;
  date: string;
  category: Category;
  lastHistory: History;
};

const columnHelper = createColumnHelper<Session>();

let timeout: any;
export default function Sessions() {
  const navigate = useNavigate();

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [search, setSearch] = React.useState('');
  const [searchTimeout, setSearchTimeout] = useState('');
  const [data, setData] = useState<Session[]>([]);
  const [total, setTotal] = useState(0);
  const [filterStatus, setFilterStatus] = React.useState('all');
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const columns = [
    columnHelper.accessor((row) => `${row.user.firstName} ${row.user.lastName}`, {
      id: 'user',
      header: () => <span>{i18next.t('user')}</span>
    }),
    columnHelper.accessor(
      (row) => `${row.therapist?.firstName || ''} ${row.therapist?.lastName || ''}`,
      {
        id: 'therapist',
        header: () => <span>{i18next.t('therapist')}</span>
      }
    ),
    columnHelper.accessor((row) => moment(row.date).format('DD MMM YYYY HH:mm'), {
      id: 'date',
      header: () => <span>{i18next.t('date')}</span>
    }),
    columnHelper.accessor((row) => row.category?.name || '', {
      id: 'category',
      header: () => <span>{i18next.t('subject')}</span>
    }),
    columnHelper.accessor((row) => row.lastHistory?.action, {
      cell: (info) => {
        return (
          <Status color={getSessionStatusColor({ action: info.getValue(), opacity: 0.4 })}>
            {i18next.t(`session_status.${info.getValue()}`)}
          </Status>
        );
      },
      id: 'lastHistory',
      header: () => <span>{i18next.t('status')}</span>
    })
  ];

  const sessions = useSessions({
    search: searchTimeout,
    sortKey: sorting?.[0]?.id,
    sortDesc: sorting?.[0]?.desc,
    page: pageIndex,
    perPage: pageSize,
    type: filterStatus
  });

  const searchSessions = (text: string) => {
    setSearch(text);
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      setSearchTimeout(text);
    }, 1000);
  };

  React.useEffect(() => {
    setData(sessions.data?.data || []);
    setTotal(sessions.data?.total || 0);
  }, [sessions.data?.data]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      pagination
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    pageCount: Math.ceil(total / pageSize),
    manualSorting: true,
    manualPagination: true
  });
  return (
    <Container>
      <AddButton
        title={i18next.t('sessions')}
        onClick={() => {
          navigate('new');
        }}
      />
      <SearchContainer>
        <SearchInput value={search} onChangeText={searchSessions} />
        <Filters
          value={filterStatus}
          filters={[
            'all',
            'requests',
            'requests-accepted',
            'requests-canceled',
            'requests-canceled-24',
            'requests-completed'
          ]}
          setFilter={setFilterStatus}
        />
      </SearchContainer>
      <TableList
        onClickItem={(item) => {
          // navigate(item.id.toString());
        }}
        table={table}
        loading={sessions.isLoading}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 30px;
`;

const Status = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  padding: 10px;
  font-weight: bold;
`;

const SearchInput = styled(Search)``;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
